import * as Sentry from "@sentry/gatsby";
import { Integrations as TracingIntegrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://c1d90b218fcc4f0d8fadc00735e1f06c@o954259.ingest.sentry.io/6147112",
  sampleRate: 1.0, // Adjust this value in production
  environment: process.env.GATSBY_RELEASE_ENVIRONMENT
    ? process.env.GATSBY_RELEASE_ENVIRONMENT
    : "development",
  integrations: [new TracingIntegrations.BrowserTracing()],
  release: "empanda@0.2.7",
});
